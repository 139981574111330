import { useContext, useEffect, useState } from 'react'
import { FireBaseContext } from '../../firebaseCtx'
import { loadImage, loadVideo } from '../../helper'
import './styles.scss'

export default function Screen(props) {
    const ctx = useContext(FireBaseContext)
    const [url, setUrl] = useState(null)
    const [item, setItem] = useState({})
    const [loadUrl, setLoadUrl] = useState(null)
    const [isLoaded, setLoaded] = useState(false)

    useEffect(() => {
        ctx.screens.map(screen => {
            if (props.id === screen.id) {
                if (screen.itemId !== item.itemId) {
                    setLoaded(false)
                    console.log(screen.itemId, item.itemId)
                    setItem(screen)
                    setLoadUrl(screen.link)
                }
            }
        })
    }, [ctx.screens])

    useEffect(() => {
        if (loadUrl) {
            if (item.type === 'image')
                loadImage(loadUrl).then(_url => {
                    setTimeout(() => {
                        setUrl(_url)
                        setLoaded(true)
                    }, 200)
                })
            if (item.type === 'video') {
                loadVideo(loadUrl).then(_url => {
                    setTimeout(() => {
                        setUrl(_url)
                        setLoaded(true)
                    }, 200)
                })
            }
        }
    }, [loadUrl])

    return (
        <div className={'screen ' + (isLoaded ? '__loaded' : '')}>
            <div className='screen__image'>
                {item.type === 'image' ?
                    <img src={url} />
                    : null}
                {item.type === 'video' ?
                    <video src={url} loop="loop" muted="muted" autoPlay="autoplay" preload="auto" playsInline="" /> 
                    : null}
            </div>
        </div>
    )
}