import { createContext, useEffect, useState } from "react";
import { getDatabase, ref, set, onValue } from "firebase/database";
import { database } from "./utils/firebaseConfig";


export const FireBaseContext = createContext();

export function FirebaseProvider({ children }) {
  const [screens, setScreens] = useState([
    {
      itemId: 1,
      id: 1,
      type: 'image',
      image: '/logo.svg',
    },
    {
      itemId: 2,
      id: 2,
      type: 'image',
      image: '/logo.svg',
    }
  ])
  const ctx = {
    screens,
    setScreens,
    setScreen: setScreen
  }

  function setScreen(screenId, item) {
    let _screens = screens.map(screen => {

      // debugger
      if (screen.id === screenId) {
        screen.itemId = item.id
        screen.type = item.type
        screen.link = item.link
      }
      return screen
    })
    console.log(_screens)
    // setScreens(_screens)
    writeData(_screens)
    // readData()
  }

  useEffect(() => {
    // Запись данных в базу данных
    const dbRef = ref(database, 'screens')
    const readData = () => {
      onValue(dbRef, (snapshot) => {
        const data = snapshot.val()
        console.log(data)
        // debugger
        if (data) {
          setScreens(data)
        }
      })
    };


    // writeData();
    readData();
  }, []);

  function writeData(screens) {
    const db = getDatabase();
    set(ref(db, 'screens'), screens);
  }

  return (
    <FireBaseContext.Provider value={ctx}>
      {children}
    </FireBaseContext.Provider>
  );
}