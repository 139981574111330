import { useContext, useState } from 'react';
import { FireBaseContext } from '../../firebaseCtx';
import './styles.scss'

export default function ScreenList(props) {
    return (
        <div className='screenList'>
            {props.screens.map(screen => <ScreenList__item {...screen} />)}
        </div>
    )
}

export function ScreenList__item(props) {
    const ctx = useContext(FireBaseContext)
    const [activeId, setActiveId] = useState(null);

    function handleClick(screenId, item) {
        // console.log(item, screenId)
        // debugger
        setActiveId(item.id)
        ctx.setScreen(screenId, item)
    }
    return (
        <div className='screenList__item'>
            <div className='_title'>
                {props.title}
            </div>
            <div className='_list'>
                {props.items.map(item => {
                    return (
                        <div className={`_item ${activeId === item.id ? '__active' : ''}`} onClick={() => handleClick(props.id, item)}>
                            {item.type === 'image' ?
                                <img src={item.link} />
                                : null}
                            {item.type === 'video' ?
                                <video src={item.link} loop="loop" muted="muted" autoPlay="autoplay" preload="auto" playsInline="" />
                                : null}

                        </div>
                    )
                })}
            </div>
        </div>
    )
}