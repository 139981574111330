import logo from './logo.svg';
import './css/styles.scss';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Screen from './pages/screen/screen';
import Main from './pages/main/main';
import { FirebaseProvider } from './firebaseCtx';

function App() {
  return (
    <div className="App">
      <FirebaseProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" index element={<Main />} />
            <Route path="/left" element={<Screen type={'left'} id={1} />} />
            <Route path="/right" element={<Screen type={'right'} id={2} />} />
            {/* <Route path="/bottom" element={<Screen type={'bottom'} id={3} />} /> */}
          </Routes>
        </BrowserRouter>
      </FirebaseProvider>
    </div>
  );
}

export default App;
