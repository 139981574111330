import { useEffect, useState } from 'react';
import ScreenList from '../../components/screen/screenList'
import ScreenPreview from '../../components/screen/screenPreview'
import './styles.scss'

export default function Main() {
    const [screens, setScreens] = useState([]);

    useEffect(() => {
        fetch('https://lector.spans.dev/api/wall/').then(res => res.json()).then(res => {
            console.log(res)
            setScreens(res)
        }).catch(err => {
            console.log(err)
        })
    }, [])

    return (
        <div className='main'>
            <div className='main_preview'>
                <ScreenPreview />
            </div>
            <div className='main_remote'>
                {screens.length ?
                <ScreenList screens={screens} />
                : null}
            </div>
        </div>
    )
}

// const screens = [
//     {
//         title: 'Экран 1',
//         id: 1,
//         items: [
//             {
//                 id: 1,
//                 type: 'image',
//                 link: 'https://pro-dachnikov.com/uploads/posts/2021-10/1633509539_25-p-gus-domashnii-ptitsi-foto-34.jpg',
//             },
//             {
//                 id: 2,
//                 type: 'image',
//                 link: 'https://inkubator56.ru/wp-content/uploads/2022/06/079.jpg',
//             },
//             {
//                 id: 3,
//                 type: 'image',
//                 link: '/examples/example_3.jpg',
//             },
//             {
//                 id: 4,
//                 type: 'image',
//                 link: '/examples/example_4.jpg',
//             },
//             {
//                 id: 5,
//                 type: 'image',
//                 link: '/examples/example_5.jpg',
//             },
//             {
//                 id: 6,
//                 type: 'video',
//                 link: 'https://oval.olympicuniversity.ru/local/templates/oval/assets/videos/zeus_bg.mp4',
//             },
//         ]
//     },
//     {
//         title: 'Экран 2',
//         id: 2,
//         items: [
//             {
//                 id: 1,
//                 type: 'image',
//                 link: '/examples/example_1.jpg',
//             },
//             {
//                 id: 2,
//                 type: 'image',
//                 link: '/examples/example_2.jpg',
//             },
//             {
//                 id: 3,
//                 type: 'image',
//                 link: '/examples/example_3.jpg',
//             },
//             {
//                 id: 4,
//                 type: 'image',
//                 link: '/examples/example_4.jpg',
//             },
//             {
//                 id: 5,
//                 type: 'image',
//                 link: '/examples/example_5.jpg',
//             },
//             {
//                 id: 6,
//                 type: 'video',
//                 link: 'https://oval.olympicuniversity.ru/local/templates/oval/assets/videos/zeus_bg.mp4',
//             },
//         ]
//     },
// ]