
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
    apiKey: "AIzaSyBKwe6RVS3yiagvsSOxphtya3XiBe1cU2g",
    authDomain: "promo-cda16.firebaseapp.com",
    databaseURL: "https://promo-cda16-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "promo-cda16",
    storageBucket: "promo-cda16.appspot.com",
    messagingSenderId: "274415943438",
    appId: "1:274415943438:web:652291c2409be9649a615f"
};

const app = initializeApp(firebaseConfig);
export const database = getDatabase(app);