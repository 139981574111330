import { useContext, useEffect, useState } from 'react'
import { FireBaseContext } from '../../firebaseCtx';
import './styles.scss'

export default function ScreenPreview() {
    const ctx = useContext(FireBaseContext);
    return (
        <div className='screenPreview'>
            <div className='screenPreview__list'>
                <ScreenPreview__item
                    screenType={'left'}
                    {...ctx.screens[0]}
                />
                <ScreenPreview__item
                    screenType={'right'}
                    {...ctx.screens[1]}
                />
                <ScreenPreview__item
                    image={'/logo.svg'}
                    screenType={'bottom'}
                    number={'3'}
                />
            </div>
        </div>
    )
}

export function ScreenPreview__item(props) {
    // const [url, setUrl] = useState('');

    // useEffect(() => {
    //     setUrl(props.link)
    // }, [props.link])

    return (

        <div className={`screenPreview__item __${props.screenType}`}>
            {props.type === 'image' ?
                <img src={props.link} />
                : null}
            {props.type === 'video' ?
                    <video src={props.link} loop="loop" muted="muted" autoPlay="autoplay" preload="auto" playsInline="" /> 

                : null}
            <div className="_number">{props.id}</div>
        </div>
    )
}

