
export const _IMAGE_CACHE = {}
export const _VIDEO_CACHE = {}


export function loadImage(url) {
    if (_IMAGE_CACHE[url]) {
        // Изображение уже есть в кэше, используем его
        return Promise.resolve(_IMAGE_CACHE[url].src);
    } else {
        return new Promise((resolve, reject) => {
            const image = new Image();

            image.onload = () => {
                _IMAGE_CACHE[url] = image; // Сохраняем его в кэше
                resolve(url);
            };

            image.onerror = () => {
                reject(new Error(`Failed to load image from ${url}`));
            };

            image.src = url;
        });
    }
}
export function loadVideo(url) {
    if (_VIDEO_CACHE[url]) {
        // Видео уже есть в кэше, используем его
        return Promise.resolve(_VIDEO_CACHE[url].src);
    } else {
        return new Promise((resolve, reject) => {
            const video = document.createElement('video');

            video.addEventListener('loadedmetadata', () => {
                _VIDEO_CACHE[url] = video; // Сохраняем его в кэше
                resolve(url);
            });

            video.addEventListener('error', () => {
                reject(new Error(`Failed to load video from ${url}`));
            });

            video.src = url;
            video.load();
        });
    }
}